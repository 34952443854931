import gqlWebClient from "../apolloClient";
import { gql } from "@apollo/client";

// (limit: 10)
export const getUsers = async function () {
  console.log("Inside getUsers()");
  const { data } = await gqlWebClient.query({
    query: gql`
      query {
        users {
          id
          email
          first_name
          last_name
          phone
          added_on
          last_updated_on
        }
      }
    `,
  });
  console.log(data);
  return data;
};

export const login = async function (email, password) {
  console.log("Inside login()");
  const { data } = await gqlWebClient.mutate({
    mutation: gql`
      mutation login($email: String!, $password: String!) {
        login(data: { email: $email, password: $password }) {
          code
          message
          http_status
          data {
            token
            user {
              id
              first_name
              last_name
              phone
              email
              zip
            }
          }
          errors {
            email
            password
          }
        }
      }
    `,
    variables: {
      email: email,
      password: password,
    },
    // errorPolicy: "all",
    // onError: function (error) {
    //   console.log("onError: ", error)
    // }
  });
  // console.log("Inside Login Function!!!");
  console.log(data);
  return data;
};

export const register = async function (
  first_name,
  last_name,
  email,
  password,
  confirmPassword,
  zip,
  phone, 
  birth_year, 
  gender, 
  marital_status, 
  education_level,
  utm_source,
  utm_campaign,
  utm_keyword,
  utm_medium,
  referrer
) {
  console.log("Inside createUser()");
  const { data } = await gqlWebClient.mutate({
    mutation: gql`
      mutation createUser(
        $first_name: String!
        $last_name: String!
        $email: String!
        $password: String!
        $confirmPassword: String!
        $zip: String
        $phone: String
        $birth_year: String
        $gender: String
        $marital_status: String
        $education_level: String
        $utm_source: String
        $utm_campaign: String
        $utm_keyword: String
        $utm_medium: String
        $referrer: String
      ) {
        createUser(
          data: {
            first_name: $first_name
            last_name: $last_name
            email: $email
            password: $password
            confirmPassword: $confirmPassword
            zip: $zip
            phone: $phone
            birth_year: $birth_year
            gender: $gender
            marital_status: $marital_status
            education_level: $education_level
            utm_source: $utm_source
            utm_campaign: $utm_campaign
            utm_keyword: $utm_keyword
            utm_medium: $utm_medium
            referrer: $referrer
          }
        ) {
          code
          message
          http_status
          data {
            token
            user {
              id
              first_name
              last_name
              phone
              email
              zip
            }
          }
          errors {
            email
            password
          }
        }
      }
    `,
    variables: {
      first_name: first_name,
      last_name: last_name,
      email: email,
      password: password,
      confirmPassword: confirmPassword,
      zip: zip,
      phone: phone,
      birth_year: birth_year,
      gender: gender,
      marital_status: marital_status,
      education_level: education_level,
      utm_source: utm_source,
      utm_campaign: utm_campaign,
      utm_keyword: utm_keyword,
      utm_medium: utm_medium,
      referrer: referrer
    },
  });

  console.log(data);
  return data;
};

export const updateUser = async function (first_name, last_name, phone, zip, birth_year, gender, marital_status, education_level) {
  console.log("Inside updateUser()");
  const { data } = await gqlWebClient.mutate({
    mutation: gql`
      mutation updateMyProfile(
        $first_name: String!
        $last_name: String!
        $phone: String
        $zip: String
        $birth_year: String
        $gender: String
        $marital_status: String
        $education_level: String
      ) {
        updateMyProfile(data: { 
          first_name: $first_name
          last_name: $last_name
          phone: $phone
          zip: $zip
          birth_year: $birth_year
          gender: $gender
          marital_status: $marital_status
          education_level: $education_level
        }) {
          code
          message
          http_status
          data {
            token
            user {
              id
              first_name
              last_name
              phone
              email
              zip
              birth_year
              gender
              marital_status
              education_level
            }
          }
          errors {
            first_name
            last_name
            phone
            zip
            birth_year
            gender
            marital_status
            education_level
          }
        }
      }
    `,
    variables: {
      first_name: first_name,
      last_name: last_name,
      zip: zip,
      phone: phone,
      birth_year: birth_year,
      gender: gender,
      marital_status: marital_status,
      education_level: education_level,
    }
  });

  console.log("updateUser API Response:", data);
  return data;
};

// links {
//     next_link
//     pervious_link
//   }

export const addFeedback = async function (email, name, phone, feedback) {
  console.log("Inside addFeedback()");
  const { data } = await gqlWebClient.mutate({
    mutation: gql`
      mutation addFeedback(
        $email: String!
        $name: String!
        $phone: String!
        $feedback: String!
      ) {
        addFeedback(
          data: {
            email: $email
            name: $name
            phone: $phone
            feedback: $feedback
          }
        ) {
          code
          message
          http_status
          data {
            id
            email
            name
            phone
            feedback
            addedOn
          }
          errors {
            email
            name
            phone
            feedback
          }
        }
      }
    `,
    variables: {
      email: email,
      name: name,
      phone: phone,
      feedback: feedback,
    },
  });

  console.log(data);
  return data;
};

export const addQuestion = async function (email, name, phone, message) {
  console.log("Inside Contact Us: addQuestion()");
  const { data } = await gqlWebClient.mutate({
    mutation: gql`
      mutation addQuestion(
        $email: String!
        $name: String!
        $phone: String!
        $message: String!
      ) {
        addQuestion(
          data: { email: $email, name: $name, phone: $phone, message: $message }
        ) {
          code
          message
          http_status
          data {
            id
            email
            name
            phone
            message
            addedOn
          }
          errors {
            email
            name
            phone
            message
          }
        }
      }
    `,
    variables: {
      email: email,
      name: name,
      phone: phone,
      message: message,
    },
  });

  console.log(data);
  return data;
};

export const verifyEmail = async function (email) {
  const { data } = await gqlWebClient.mutate({
    mutation: gql`
      mutation ($email: String!) {
        verifyEmail(data: { email: $email }) {
          message
          code
          http_status
          __typename
        }
      }
    `,
    variables: {
      email: email,
    },
  });
  return data;
};


export const deleteAccount = async function (
  email,
  password,
) {
  console.log("delete user account !");
  const { data } = await gqlWebClient.mutate({
    mutation: gql`
      mutation deleteMyProfile(
        $email: String!
        $password: String!
      ) {
        deleteMyProfile(
          data: {
            email: $email
            password: $password
          }
        ) {
          code
          message
          http_status
          errors {
            email
            password
          }
        }
      }
    `,
    variables: {
      email: email,
      password: password
    },
  });

  console.log(data);
  return data;
};

export const addSource = async function(source){
  const {data} = await gqlWebClient.mutate({
    mutation: gql`
    mutaion 
    `
  })
}
export const getUserDetails = async function () {
  console.log("Inside getUsers()");
  const { data } = await gqlWebClient.query({
    query: gql`
      query {
        currentUser {
          first_name
          last_name
          phone
          zip
          birth_year
          gender
          marital_status
          education_level          
        }
      }
    `,
  });
  console.log(data);
  return data;
};